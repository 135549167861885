<template>
    <div class="wrapper flex jc-c ai-fs"></div>
</template>

<script>
import { login, ixmToken, ixmAuth, ixmAppLogin, ixmAppAuthorize } from "../../api";
export default {
    components: {},
    props: {},
    data() {
        return {
            num: 0
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取当前网页的协议+域名
        getPageBaseUrl() {
            var baseURL = window.location.origin + window.location.pathname;
            return baseURL;
        },
        getParameter(data) {
            var parameter = '?'
            for (var item in data) {
                parameter = parameter + item + '=' + data[item] + '&'
            }
            parameter = parameter.substring(0, parameter.length - 1)
            return parameter
        },
        ixmToken() {
            var url = ''
            if (this.$route.query.path) {
                url = this.getPageBaseUrl() + '#/login' + this.getParameter(this.$route.query)
            } else {
                url = this.getPageBaseUrl() + '#/login'
            }
            ixmToken({
                url: encodeURIComponent(url)
            }).then(res => {
                location.replace(res.data.data)
            })
        },
        ixmAuth(code) {
            ixmAuth({
                token: code
            }).then(res => {
                var data = res.data.data;
                this.$store.state.newGift = data.newGift
                var token = data.tokenHead + " " + data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("tokenHeader", data.tokenHeader);
                if (this.$route.query.path) {
                    this.$router.replace({ path: this.$route.query.path, query: this.$route.query });
                } else {
                    this.$router.replace({ path: "/home" });
                }
            })
        },
        // 获取连接参数正则
        getQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },
        //市民卡登录
        login(token) {
            login({
                code: token,
                // code: token,
            }).then((res) => {
                var data = res.data.data;
                this.$store.state.newGift = data.newGift
                var token = data.tokenHead + " " + data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("tokenHeader", data.tokenHeader);

                if (this.$route.query.path) {
                    this.$router.replace({ path: this.$route.query.path, query: this.$route.query });
                } else {
                    this.$router.replace({ path: "/home" });
                }
            }).catch(() => {
                this.num++
                if (this.num <= 3) {
                    this.smkCode()
                }
            });
        },
        smkCode() {
            // sceneType可以传空，特定接入方需要
            var that = this
            function getToken(type, sceneType = "") {
                UmsApi.globalization.getToken(
                    {
                        tokenType: type,
                        sceneType: sceneType,
                    },
                    function (data) {
                        //成功
                        that.login(data.token)
                    },
                    function (data) {
                        //失败
                    }
                );
            }
            var type = "full";
            getToken(type);
        },
        ixmAppLogin(code) {
            ixmAppLogin({
                token: code,
                url: encodeURIComponent(this.getPageBaseUrl() + '#' + this.$route.fullPath)
            }).then(res => {
                var data = res.data.data;
                this.$store.state.newGift = data.newGift
                var token = data.tokenHead + " " + data.token;
                localStorage.setItem("token", token);
                localStorage.setItem("tokenHeader", data.tokenHeader);
                localStorage.setItem("thirdSystemId", data.thirdSystemId);
                localStorage.setItem("payThirdSystemId", data.payThirdSystemId);
                if (this.$route.query.path) {
                    this.$router.replace({ path: this.$route.query.path, query: this.$route.query });
                } else {
                    this.$router.replace({ path: "/home" });
                }
            })
        },
        ixmAppAuthorize() {
            ixmAppAuthorize({
                url: encodeURIComponent(this.getPageBaseUrl() + '#' + this.$route.fullPath)
            }).then(res => {
                location.replace(res.data.data)
            })
        },
    },
    mounted() {
        // var ua = navigator.userAgent.toLowerCase();
        // console.log(ua);
        // return this.ixmAppLogin('gwq')
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
            console.log('在微信端');
            if (this.getQueryString('token') || this.$route.query.token) {
                this.ixmAuth(this.getQueryString('token') || this.$route.query.token)
            } else {
                this.ixmToken()
            }
            // this.ixmAuth('gwq')
        } else if (ua.match(/xmsmk/i) == "xmsmk") {
            var preloadJs = function () {
                // Promise语法是ES6⽀持的，其他不⽀持的请⾃⾏转成ES5
                return new Promise((resolve, reject) => {
                    if (ua.toLowerCase().indexOf("xmsmk") !== -1) {
                        if (window.UmsApi === undefined) {
                            document.addEventListener(
                                "OnUmsApiReady",
                                function () {
                                    resolve(true);
                                },
                                false
                            );
                        } else {
                            resolve(true);
                        }
                    } else {
                        // ⾮市⺠卡接⼊ 
                        reject("⾮市⺠卡接⼊");
                    }
                });
            };
            preloadJs().then((res) => {
                this.smkCode()
                // 调⽤市⺠卡相关⽅法
            });
        } else {
            if (this.getQueryString('code') || this.$route.query.code) {
                this.ixmAppLogin(this.getQueryString('code') || this.$route.query.code)
            } else {
                this.ixmAppAuthorize()
            }
        }

    },
};
</script>
<style scoped>
.wrapper {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
}

.wrapper .box {
    width: 6.4rem;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0rem 0rem 0.2rem 0rem rgba(252, 141, 81, 0.1);
    border-radius: 0.2rem;
}

.wrapper .box .title {
    width: calc(100% - 0.5rem);
    font-size: 0.32rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    margin: 0.3rem 0 0 0.5rem;
}

.wrapper .box .input-box {
    width: 6.08rem;
    height: 0.8rem;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0rem 1px 0rem 0rem rgba(0, 0, 0, 0.05);
    border-radius: 0.4rem;
    margin-top: 0.3rem;
}

.wrapper .box .input-box .icon {
    width: 0.36rem;
    height: 0.36rem;
    margin-left: 0.25rem;
    margin-right: 0.18rem;
}

.wrapper .box .input-box input {
    flex: 1;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 400;
    padding-right: 0.4rem;
    color: #333;
    border: 0;
    background: rgba(0, 0, 0, 0);
}

.wrapper .box .input-box .input-btn {
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #9a9a9a;
    margin-right: 0.2rem;
}

.wrapper .box .agreement {
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #343434;
    line-height: 100%;
    margin-top: 0.4rem;
}

.wrapper .box .agreement font {
    color: rgba(255, 110, 0, 1);
}

.wrapper .box .btn {
    width: 5.8rem;
    height: 0.8rem;
    background: linear-gradient(90deg, #ff9036, #ff7a2a);
    border-radius: 0.2rem;
    margin-top: 0.37rem;
    font-size: 0.32rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
}

.wrapper .box .btn-box {
    width: 100%;
    margin: 0.4rem 0;
}

.wrapper .box .btn-box .btn1,
.wrapper .box .btn-box .btn2 {
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ff6e00;
    margin: 0 0.16rem;
}

.wrapper .box .btn-box .btn2 {
    color: #fff;
}
</style>